import React from "react";
import { calculateAuctionStatus } from "./Utils";
function AuctionStatusBadge(props) {
    const status = calculateAuctionStatus(props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset)
    switch (status) {
        case 'Unavailable':
            return <div className="badge badge-dark badge-pill m-1 text-capitalize ">{status}</div>
        case 'Awaiting':
            return <div className="badge badge-dark badge-pill m-1 text-capitalize ">{status}</div>
        case 'Open':
            return <div className="badge badge-success badge-pill m-1 text-capitalize ">{status}</div>
        case 'Extended':
            return <div className="badge badge-warning badge-pill m-1 text-capitalize ">{status}</div>
        case 'Completed':
            return <div className="badge badge-danger badge-pill m-1 text-capitalize ">{status}</div>
        default:
            return <React.Fragment />
    }
}

export default AuctionStatusBadge;