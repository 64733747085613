import { useState, useCallback, useMemo } from "react";
import { Countdown } from '@/components/library/Countdown'
import { calculateAuctionStatus, getEndTime } from "./Utils";
import AuctionStatusBadge from "./AuctionStatusBadge";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";
function AuctionStatusBadgeTimer(props) {
    const [lastRefreshed, setLastRefreshed] = useState(new Date().getTime());
    const status = useMemo(() => lastRefreshed && calculateAuctionStatus(props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset),
        [props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset, lastRefreshed]);

    const CountdownRenderer = useCallback(() => {
        return status && lastRefreshed && <AuctionStatusBadge isActive={props.isActive} startDateTimeAt={props.startDateTimeAt} endDateTimeAt={props.endDateTimeAt} extendedBy={props.extendedBy} dateTimeOffset={props.dateTimeOffset} />
    }, [status, lastRefreshed, props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.dateTimeOffset, props.extendedBy])

    return <Countdown
        date={getEndTime(props.endDateTimeAt, props.extendedBy)}
        renderer={CountdownRenderer}
        now={() => getNewDateWithOffset(props.dateTimeOffset).getTime()}
        onComplete={() => {
            if (status !== 'Completed') setLastRefreshed(new Date().getTime())
        }}
    />
}

export default AuctionStatusBadgeTimer;