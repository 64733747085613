import React, { FC, memo, useCallback } from 'react';
import { Countdown } from '@/components/library/Countdown'
import { getEndTime } from "@/components/AuctionManagement/Utils";
import { getNewDateWithOffset } from 'prembid-shared-library-npm/helpers';
import { Typography } from '@mui/material';

interface TimeRemainingCountdownProps {
    endDateTimeAt: string;
    extendedBy: number;
    refreshStatus: () => void;
    dateTimeOffset: number;
}

const TimeRemainingCountdown: FC<TimeRemainingCountdownProps> = ({ endDateTimeAt, extendedBy, refreshStatus, dateTimeOffset }) => {

    const CountdownRenderer = useCallback(({ days, hours, minutes, seconds }) => {
        let text = `${days}D ${hours}H ${minutes}M ${seconds}S`;

        return <Typography variant="subtitle2" style={{ minWidth: text.length + 'ch' }}>{text}</Typography>
    }, [])

    return <Countdown date={getEndTime(endDateTimeAt, extendedBy)} renderer={CountdownRenderer} onComplete={refreshStatus} now={() => getNewDateWithOffset(dateTimeOffset).getTime()} />
}

export default memo(TimeRemainingCountdown)