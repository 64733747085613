import React, { memo, FC, useMemo, useCallback, useState, useEffect, } from "react";
import {
    Button, TextField, List, ListItem, CircularProgress, Popover, InputAdornment, Tooltip,
    Table, Grid, Card, Typography, TableContainer, TableBody, TableCell, TableRow,
    TableHead, Paper, Box,
    PopoverPosition
} from "@mui/material";
import { FaSave, FaPlus, FaTrashAlt, FaEdit, FaLockOpen, FaUserCog, } from "react-icons/fa";
import { maxBy, orderBy } from "lodash";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import useStyles from "../AuctionsOverviewGrid.styles";
import { Lot } from "prembid-shared-library-npm/types";
import { BidDeleteModalState, BidEditModalState, BidRow, BidTableAction, CurrentLotReserves, ExtendLotModalState, ExtensionDefaultTimes, IncrementTable, LockedLots, LotsAskingBids, LotsIncrementTables } from "../AuctionsOverviewGrid.types";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/ConfigureStore";
import ConfirmationDialog from "../../Modal/ConfirmationDialog";
import ns from "@/helpers/NotificationService";
import { useExtendLot, useLockLot, useUnlockLot, useUpdateIncrement, useUpdateLateBidExtension, useUpdateOrDeleteBid, useUpdateReservePrice, useUpdateStartPrice } from "prembid-shared-library-npm/networking";
import BidUpdateDialog from "../../Modal/BidUpdateDialog";
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import { hideProgressSpinner, showProgressSpinner } from "../../../helpers/ProgressSpinnerService";
import { formatDateTime } from "../../../helpers/Utils";
import { FaCoins } from "react-icons/fa";
import { PrembidLocalStorage } from "../../../helpers/PrembidLocalStorage";
import BidButton from "./BidButton";
import CustomBidButton from "./CustomBidButton";
import MaximumBidButton from "./MaximumBidButton";
import { IdentityRoles } from "../../../dto/User";
import { MdLockPerson } from "react-icons/md";

interface BidsPopoverProps {
    auctionId: string;
    auctionType: AuctionTypeEnum,
    lot: Lot;
    isLocked: boolean;
    bidRows: BidRow[];
    incrementTable: IncrementTable;
    lotBidsLoading: boolean;
    lotBidsFetching: boolean;
    anchorEl: HTMLElement;
    handleClosePopover: () => void;
    loadBiddingData: (lotId: string, startingPrice?: number, increment?: number) => void;
    initialLotAskingBid: number;
    isTender: boolean;
    refreshList: () => void;
    tagLockedNoLiveNotifications: (lotId: string, lotName: string) => void;
    tagUnlockedNoLiveNotifications: (lotId: string, lotName: string) => void;
    setTableRowData: React.Dispatch<any>;
    setLockedLots: React.Dispatch<React.SetStateAction<LockedLots>>;
    setLotsIncrementTables: React.Dispatch<React.SetStateAction<LotsIncrementTables>>;
    setLotsAskingBids: React.Dispatch<React.SetStateAction<LotsAskingBids>>;
    setCurrentLotReserves: React.Dispatch<React.SetStateAction<CurrentLotReserves>>;
    adminBiddingProfile: any;
}

const BidsPopover: FC<BidsPopoverProps> = (props) => {
    const { classes, cx } = useStyles();

    const getExtensionDefaultTimes = (seconds: number) => {
        const time = {
            hour: 0,
            min: 0,
            sec: 0,
        };

        time.hour = ~~(seconds / 3600);
        time.min = ~~((seconds % 3600) / 60);
        time.sec = ~~seconds % 60;

        return time;
    };

    const {
        auctionId,
        lot,
        isLocked,
        bidRows,
        incrementTable,
        lotBidsLoading,
        lotBidsFetching,
        anchorEl,
        handleClosePopover,
        loadBiddingData,
        initialLotAskingBid,
        isTender,
        refreshList,
        tagLockedNoLiveNotifications,
        tagUnlockedNoLiveNotifications,
        auctionType,
        setTableRowData,
        setLockedLots,
        setLotsIncrementTables,
        setLotsAskingBids,
        setCurrentLotReserves,
        adminBiddingProfile
    } = props;

    const settings = useSelector((state: RootState) => state.settings.settings);

    const {
        id: lotId,
        startingPrice,
        defaultMinimumIncrement: increment,
        defaultBidExtension: seconds,
        reservePrice: reserve,
    } = lot;

    const [lotBidCount, setLotBidCount] = useState(0);
    const [lotCurrentBid, setLotCurrentBid] = useState(0);
    const [lotAskingBid, setLotAskingBid] = useState(initialLotAskingBid);
    const [openingBid, setOpeningBid] = useState(startingPrice ?? 0);
    const [reservePrice, setReservePrice] = useState(reserve ?? 0);
    const [newExtension, setNewExtension] = useState<any>(getExtensionDefaultTimes(seconds));
    const [newIncrement, setNewIncrement] = useState<number>(increment);
    const [extendLotModalState, setExtendLotModalState] = useState<ExtendLotModalState>({ isOpen: false });
    const [extensionAnchorEl, setExtensionAnchorEl] = useState(null);
    const [bidDeleteModal, setBidDeleteModal] = useState<BidDeleteModalState>({ isOpen: false });
    const [bidEditModal, setBidEditModal] = useState<BidEditModalState>({ isOpen: false });

    const increments = useMemo(() => (incrementTable?.incrementTableDataCaptured ? incrementTable?.incrementTableDataCaptured?.increments : null), [incrementTable]);
    const handleSetOpeningBid = useCallback((event) => setOpeningBid(Number(event.target.value)), []);
    const handleSetReservePrice = useCallback((event) => setReservePrice(Number(event.target.value)), []);
    const onIncrementChange = useCallback((e: { target: { id: any; value: string } }) => setNewIncrement(Number(e.target.value)), []);
    const highestBidRow = useMemo(() => maxBy(bidRows, "amount"), [bidRows]);

    const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>();

    const [refreshRequired, setRefreshRequired] = useState(false);

    const deleteBidConfirmationDescription = useMemo(
        () => (
            <>
                Deleting this {isTender ? "tender" : "bid"} will cause the system to
                revert to the previous {isTender ? "tender" : "bid"}.
                <br />
                <br />A record of this transaction will still be stored.
            </>
        ),
        [isTender]
    );

    const secondHighestBidAmount = useMemo(() => {
        const amounts = bidRows.map(({ amount }) => amount);
        const sortedAmounts = orderBy(amounts, [], ["desc"]);
        return sortedAmounts[1] ?? 0;
    }, [bidRows]);

    const setExtensionNumeric = useCallback((numericValue: number, type: string) => {
        if (!Number.isNaN(numericValue)) {
            var type = type;
            var valid = false;

            switch (type) {
                case "hour":
                    valid = numericValue > -1 && numericValue <= 72;
                    break;
                case "min":
                    valid = numericValue > -1 && numericValue <= 59;
                    break;
                case "sec":
                    valid = numericValue > -1 && numericValue <= 59;
                    break;
            }

            if (valid) setNewExtension({ ...newExtension, [type]: numericValue });
        }
    }, [newExtension]);

    const onChangeExtensionNumeric = useCallback((e: any) => {
        if (e.target.value !== "") {
            const numericValue = Number(e.target.value);
            setExtensionNumeric(numericValue, e.target.id);
        }
    }, [setExtensionNumeric]);

    const getIncrement = useCallback((increment: number = 0, bidAmount: number = 0) => {
        if (increments) {
            const incrementRow = increments?.find(x => bidAmount >= Number(x.fromAmount) && bidAmount <= Number(x.toAmount));
            if (incrementRow) increment = Number(incrementRow.increment);
            else if (bidAmount > 0 && increments.length > 0) {
                const lastIncrementRow = increments[increments?.length - 1];
                increment = Number(lastIncrementRow?.increment);
            }
        }
        return increment;
    }, [incrementTable?.incrementTableDataCaptured])

    const updateExtensionHour = useCallback((e) => {
        setExtensionNumeric(Number(newExtension.hour) + 1, "hour");
    }, [newExtension.hour, setExtensionNumeric]);

    const updateExtensionMin = useCallback((e) => {
        setExtensionNumeric(Number(newExtension.min) + 1, "min");
    }, [newExtension.min, setExtensionNumeric]);

    const updateExtensionSec = useCallback((e) => {
        setExtensionNumeric(Number(newExtension.sec) + 5, "sec");
    }, [newExtension.sec, setExtensionNumeric]);

    const handleCloseExtensionPopover = useCallback(() => {
        setExtensionAnchorEl(null);
    }, []);

    const handleClickExtensionPopover = useCallback((event) => {
        setExtensionAnchorEl(event.currentTarget);
    }, []);

    const closeLotExtensionConfirmation = useCallback(
        () => setExtendLotModalState((prev) => ({ ...prev, isOpen: false })),
        []
    );

    const openLotExtensionConfirmation = useCallback(
        (lotId: string) => setExtendLotModalState((prev) => ({ ...prev, lotId, isOpen: true })),
        []
    );

    const { isLoading: updateLateBidExtensionIsLoading, mutate: updateLateBidExtension } = useUpdateLateBidExtension({
        onSuccess: () => setRefreshRequired(true),
        retry: false
    });
    const handleUpdateLateBidExtension = (newExtension: ExtensionDefaultTimes, { id: lotId }: Lot) => {
        updateLateBidExtension(
            {
                AuctionId: auctionId,
                Id: lotId,
                DefaultBidExtension:
                    Number(newExtension.hour) * 3600 +
                    Number(newExtension.min) * 60 +
                    Number(newExtension.sec),
            },
            {
                onSuccess() {
                    ns.success("Updated successfully.");
                },
            }
        );
    };

    const { isLoading: extendLotIsLoading, mutate: extendLot } = useExtendLot({
        retry: false
    });
    const confirmExtendLot = () => {
        closeLotExtensionConfirmation();
        extendLot(
            { auctionId, lotId },
            {
                onSuccess() {
                    ns.success("Updated successfully.");
                },
            }
        );
    };

    const { isLoading: unlockLotIsLoading, mutate: unlockLot } = useUnlockLot();

    const unlockLotWithErrorMessage = useCallback((lotId?: string) => {
        ns.error(ns.DefaultMessages.Error);

        if (lotId) {
            unlockLot(
                {
                    queryParams: { lotId }
                },
                {
                    onSuccess() {
                        setLockedLots(prev => ({ ...prev, [lotId]: false }));
                    },
                }
            );
        }
    }, [unlockLot]);

    const manuallyUnlockLot = useCallback(({ id: lotId, name: lotName }: Lot) => {
        unlockLot(
            {
                queryParams: { lotId }
            },
            {
                onSuccess() {
                    tagUnlockedNoLiveNotifications(lotId, lotName);
                },
            }
        );
    }, [tagUnlockedNoLiveNotifications, unlockLot]);

    const { isLoading: lockLotIsLoading, mutate: lockLot } = useLockLot();

    const { isLoading: updateOrDeleteBidIsLoading, mutate: updateOrDeleteBid } = useUpdateOrDeleteBid({
        retry: false
    });
    const handleBidDeleteButtonPress = useCallback(
        (bidId: string, { id: lotId, name: lotName }: Lot) => {
            lockLot(
                {
                    queryParams: { lotId }
                },
                {
                    onSuccess() {
                        setBidDeleteModal({
                            bidId,
                            lotId,
                            lotName,
                            isOpen: true,
                        });

                        tagLockedNoLiveNotifications(lotId, lotName);
                    },
                }
            );
        },
        [lockLot, tagLockedNoLiveNotifications]
    );

    const hideBidDeleteModal = useCallback(() => {
        setBidDeleteModal((prev) => ({ ...prev, isOpen: false }));
    }, []);

    const cancelBidDelete = useCallback(() => {
        const { lotId, lotName } = bidDeleteModal;

        if (!lotId || !lotName) return;

        unlockLot({ queryParams: { lotId }, }, {
            onSuccess() {
                hideBidDeleteModal();
                tagUnlockedNoLiveNotifications(lotId, lotName);
            },
        });
    }, [bidDeleteModal, hideBidDeleteModal, tagUnlockedNoLiveNotifications, unlockLot]);

    const confirmBidDelete = useCallback(
        () => {

            const { bidId, lotId, lotName } = bidDeleteModal;
            hideBidDeleteModal();

            if (!bidId || !lotId || !lotName) {
                return unlockLotWithErrorMessage(lotId);
            }

            updateOrDeleteBid(
                { bidId, delete: true },
                {
                    onSuccess() {
                        setTableRowData({
                            type: BidTableAction.RemoveBid,
                            data: {
                                lotId,
                                bidId,
                            },
                        });

                        tagUnlockedNoLiveNotifications(lotId, lotName);
                    },
                }
            );
        },
        [bidDeleteModal, hideBidDeleteModal, tagUnlockedNoLiveNotifications, unlockLotWithErrorMessage, updateOrDeleteBid]
    );

    const handleBidEditButtonPress = useCallback(
        ({
            lot,
            bidId,
            paddleNumber,
            bidAmount,
            minAllowedBidAmount,
        }: Omit<BidEditModalState, "isOpen">) => {

            if (!lot) return;

            const {
                id: lotId,
                name: lotName,
            } = lot;

            lockLot(
                { queryParams: { lotId } },
                {
                    onSuccess() {
                        setBidEditModal({
                            lot,
                            bidId,
                            paddleNumber,
                            bidAmount,
                            minAllowedBidAmount,
                            isOpen: true,
                        });

                        tagLockedNoLiveNotifications(lotId, lotName);
                    },
                }
            );
        },
        [lockLot, tagLockedNoLiveNotifications]
    );

    const hideBidEditModal = useCallback(() => {
        setBidEditModal((prev) => ({ ...prev, isOpen: false }));
    }, []);

    const cancelBidEdit = useCallback(() => {

        const { lot } = bidEditModal;

        if (!lot) return;

        const { id: lotId, name: lotName } = lot;

        unlockLot(
            {
                queryParams: { lotId }
            },
            {
                onSuccess() {
                    hideBidEditModal();
                    tagUnlockedNoLiveNotifications(lotId, lotName);
                },
            }
        );
    }, [bidEditModal, hideBidEditModal, tagUnlockedNoLiveNotifications, unlockLot]);

    const confirmBidEdit = useCallback(
        ({
            bidId,
            bidAmount,
        }: Required<Pick<BidEditModalState, "bidId" | "bidAmount">>) => {

            const { lot } = bidEditModal;

            if (!lot) return;

            const { id: lotId, name: lotName } = lot;

            hideBidEditModal();

            if (bidId && bidAmount) {
                updateOrDeleteBid(
                    { bidId, bidAmount },
                    {
                        onSuccess() {
                            setTableRowData({
                                type: BidTableAction.UpdateBidAmount,
                                data: {
                                    bidId,
                                    amount: bidAmount,
                                },
                            });

                            tagUnlockedNoLiveNotifications(lotId, lotName);
                        },
                    }
                );
            } else {
                unlockLotWithErrorMessage();
            }
        },
        [bidEditModal, hideBidEditModal, tagUnlockedNoLiveNotifications, unlockLotWithErrorMessage, updateOrDeleteBid]
    );


    const { isLoading: updateIncrementIsLoading, mutate: updateIncrement } = useUpdateIncrement({
        onSuccess: () => setRefreshRequired(true),
        retry: false
    });
    const handleUpdateIncrement = useCallback((lotId: string, newIncrement: string | number, lotCurrentBid: number) => {
        const newIncrementNumber = Number(newIncrement);

        if (newIncrementNumber <= 0) {
            ns.error("The increment must be greater than 0.");
            return;
        }

        updateIncrement(
            {
                AuctionId: auctionId,
                Id: lotId,
                DefaultMinimumIncrement: newIncrementNumber,
            },
            {
                onSuccess() {
                    setLotsIncrementTables(prev => ({ ...prev, [lotId]: {} }));

                    if (Number(lotCurrentBid) > 0) {
                        setLotsAskingBids(prev => ({ ...prev, [lotId]: Number(lotCurrentBid) + Number(newIncrementNumber) }));
                    } else {
                        setLotsAskingBids(prev => ({ ...prev, [lotId]: Number(newIncrementNumber) }));
                    }

                    ns.success("Updated successfully.");
                },
            }
        );
    }, [auctionId, updateIncrement]);

    const { isLoading: updateReservePriceIsLoading, mutate: updateReservePrice } = useUpdateReservePrice({
        onSuccess: () => setRefreshRequired(true),
        retry: false
    });
    const handleUpdateReservePrice = useCallback((reservePrice: number, { id: lotId }: Lot) => {
        updateReservePrice(
            { auctionId, lotId, reservePrice },
            {
                onSuccess() {
                    ns.success("Updated successfully.");
                    setCurrentLotReserves(prev => ({ ...prev, [lotId]: reservePrice }));
                },
            }
        );
    }, [updateReservePrice, auctionId]);

    const { isLoading: updateStartPriceIsLoading, mutate: updateStartPrice } = useUpdateStartPrice({
        onSuccess: () => setRefreshRequired(true),
        retry: false
    });
    const handleUpdateStartPrice = useCallback((openingBid: number, { id: lotId }: Lot) => {
        updateStartPrice(
            { auctionId, lotId, openingBid },
            {
                onSuccess() {
                    ns.success("Updated successfully.");
                },
            }
        );
    }, [updateStartPrice, auctionId]);

    const handleClose = useCallback(() => {
        handleClosePopover();
        if (refreshRequired) refreshList();
    }, [handleClosePopover, refreshList, refreshRequired]);

    const reposition = useCallback(() => {
        const anchorRect = anchorEl.getBoundingClientRect();
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        const popoverTop = anchorRect.top + 2 * anchorRect.height - scrollPosition;
        const popoverLeft = anchorRect.left;
        const windowHeight = window.innerHeight;

        const trackingPosition = scrollPosition + popoverTop;

        setAnchorPosition({ top: popoverTop, left: popoverLeft })

        if (trackingPosition < 0 || trackingPosition > windowHeight) {
            handleClose();
        }
    }, [anchorEl, handleClose]);

    useEffect(() => {
        window.addEventListener("scroll", reposition);
        reposition();

        return () => window.removeEventListener("scroll", reposition);
    }, [reposition]);

    useEffect(() => {
        const { amount, paddleNumber: bidder } = bidRows[0] ?? {};

        if (!amount && openingBid > 0)
            setLotAskingBid(openingBid);
        else
            if (amount && bidder) {
                setLotCurrentBid(amount);
                setLotAskingBid(amount + getIncrement(newIncrement, amount));
            } else {
                setLotCurrentBid(0);
                setLotAskingBid(getIncrement(newIncrement ?? 0, amount) ?? 0);
            }

        setLotBidCount(bidRows.length);
    }, [bidRows, getIncrement, newIncrement, openingBid]);

    useEffect(() => {
        if (lockLotIsLoading) {
            return showProgressSpinner({
                description: "Acquiring lock. Please wait...",
            });
        }

        if (unlockLotIsLoading) {
            return showProgressSpinner({
                description: "Releasing lock. Please wait...",
            });
        }

        if (updateOrDeleteBidIsLoading) {
            return showProgressSpinner({ description: "Please wait..." });
        }

        hideProgressSpinner();
    }, [lockLotIsLoading, unlockLotIsLoading, updateOrDeleteBidIsLoading]);



    const showLoadingLateBidExtensionMainButton = updateLateBidExtensionIsLoading && !!extensionAnchorEl;

    const canEditDelete = auctionType === AuctionTypeEnum.Timed;

    return (anchorPosition && <Popover
        disableScrollLock={false}
        open={true}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
            paper: classes.popover,
        }}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
    >
        <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={2}
            className="p-3">
            <Grid item md={(isTender || !(adminBiddingProfile?.paddleNumber && PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalBiddingUser))) ? 9 : 6}>
                <Card className="p-3" style={{ minHeight: 125 }}>
                    {lotBidsLoading ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress
                                className="text-primary text-center"
                                size={80}
                                style={{ margin: "auto" }}
                            />
                        </div>
                    ) : (
                        <>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                spacing={1}>
                                <Grid item lg={2}>
                                    <h5 className="text-primary">
                                        {isTender
                                            ? "Tenders (" + lotBidCount + ")"
                                            : "Bids (" + lotBidCount + ")"}
                                    </h5>
                                </Grid>
                                <Grid item lg={!isTender ? 3 : 8}>
                                    <h5 className="text-black">
                                        {isTender ? "Top Tender" : "Current Bid"}
                                    </h5>
                                    <h5 className="text-black font-weight-bold">
                                        <CurrencyLabel value={lotCurrentBid.toString()} />
                                    </h5>
                                </Grid>
                                {!isTender && (
                                    <>
                                        <Grid item lg={3}>
                                            <h5 className="text-black">Asking Bid </h5>
                                            <h5 className="text-black font-weight-bold">
                                                <CurrencyLabel value={lotAskingBid.toString()} />
                                            </h5>
                                        </Grid>
                                        <Grid item lg={2}>
                                            {incrementTable?.incrementTableName ? (
                                                <>
                                                    <h5 className="text-danger">Increment Table</h5>
                                                    <h5 className="text-danger">
                                                        {incrementTable?.incrementTableName}
                                                    </h5>
                                                </>
                                            ) : (
                                                <>
                                                    <h5 className="text-danger">Manual Increment</h5>
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                )}
                                <Grid item lg={2}>
                                    <Grid container direction="row" justifyContent="flex-end">
                                        {bidRows.length > 0 && (
                                            <Grid style={{ margin: "5px" }}>
                                                <Tooltip title="Unlock" placement="bottom">
                                                    <Button
                                                        onClick={() => manuallyUnlockLot(lot)}
                                                        variant="text"
                                                        className={cx(
                                                            "btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill text-capitalize"
                                                        )}>
                                                        <FaLockOpen />
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            {bidRows.length > 0 ? (
                                <>
                                    <div
                                        className="mt-2 mb-2"
                                        style={{
                                            display: "block",
                                            maxHeight: 253,
                                            overflowY: "scroll",
                                        }}>
                                        <TableContainer component={Paper}>
                                            <Table classes={{ root: classes.table }}>
                                                <TableHead classes={{ root: classes.header }}>
                                                    <TableRow classes={{ root: classes.headerRow }}>
                                                        <TableCell classes={{ root: classes.headerCell }}>
                                                            Amount
                                                        </TableCell>
                                                        <TableCell classes={{ root: classes.headerCell }}>
                                                            Paddle Number
                                                        </TableCell>
                                                        <TableCell classes={{ root: classes.headerCell }}>
                                                            Date and Time Stamp
                                                        </TableCell>
                                                        <TableCell classes={{ root: classes.headerCell }}>
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bidRows.map(
                                                        (
                                                            { bidId, amount, name, paddleNumber, date, time, maxBidCreatedAt, maxBidAmount, type },
                                                            index
                                                        ) => (
                                                            <TableRow key={`${bidId}-${index}`}>
                                                                <TableCell classes={{ root: classes.cell }}>
                                                                    <CurrencyLabel value={amount} />
                                                                </TableCell>
                                                                <TableCell classes={{ root: classes.topBidderCell }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <div style={{ flex: 1 }}>
                                                                            <Box>{name}</Box>
                                                                            <Box color={settings?.Styles?.Primary}>{paddleNumber}</Box>
                                                                        </div>
                                                                        {type === "admin" && <Tooltip
                                                                            arrow
                                                                            placement='top'
                                                                            title={<>
                                                                                <div style={{ paddingBottom: 3 }}>Admin User {maxBidCreatedAt ? "Max " : ""} {isTender ? "Tender" : "Bid"}</div>
                                                                                {maxBidCreatedAt && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                                                                                    <div style={{ flex: 1 }}>
                                                                                        <div>Created Date: </div>
                                                                                        <div>Amount: </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{formatDateTime(maxBidCreatedAt, "yyyy/MM/dd HH:mm:ss")}</div>
                                                                                        <div>{<CurrencyLabel value={maxBidAmount} textColour={"#ffffff"} />}</div>
                                                                                    </div>
                                                                                </div>}
                                                                            </>}
                                                                        >
                                                                            <div>
                                                                                <FaUserCog size={22} />
                                                                            </div>
                                                                        </Tooltip>}

                                                                        {type !== "admin" && maxBidCreatedAt && <Tooltip
                                                                            arrow
                                                                            placement='top'
                                                                            title={<>
                                                                                <div style={{ paddingBottom: 3 }}>User Max Bid</div>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>

                                                                                    <div style={{ flex: 1 }}>
                                                                                        <div>Created Date: </div>
                                                                                        <div>Amount: </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div>{formatDateTime(maxBidCreatedAt, "yyyy/MM/dd HH:mm:ss")}</div>
                                                                                        <div>{<CurrencyLabel value={maxBidAmount} textColour={"#ffffff"} />}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </>}

                                                                        >
                                                                            <div>
                                                                                {type === "admin" ? <MdLockPerson size={20} /> : <FaCoins size={20} />}
                                                                            </div>
                                                                        </Tooltip>}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell classes={{ root: classes.cell }}>
                                                                    {date} {time}
                                                                </TableCell>
                                                                <TableCell classes={{ root: classes.cell }}>
                                                                    {canEditDelete && <>
                                                                        {highestBidRow?.amount === amount && (
                                                                            <>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    title={
                                                                                        isLocked
                                                                                            ? "Someone is currently modifying this bid."
                                                                                            : "Edit"
                                                                                    }
                                                                                    placement="bottom">
                                                                                    <span>
                                                                                        <Button
                                                                                            disabled={isLocked}
                                                                                            onClick={() =>
                                                                                                handleBidEditButtonPress({
                                                                                                    lot,
                                                                                                    bidId,
                                                                                                    paddleNumber,
                                                                                                    bidAmount: amount,
                                                                                                    minAllowedBidAmount:
                                                                                                        secondHighestBidAmount,
                                                                                                })
                                                                                            }
                                                                                            size="small"
                                                                                            className="btn-primary mx-1 my-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                                                                            <FaEdit />
                                                                                        </Button>
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    title={
                                                                                        isLocked
                                                                                            ? "Someone is currently modifying this bid."
                                                                                            : "Delete"
                                                                                    }
                                                                                    placement="bottom">
                                                                                    <span>
                                                                                        <Button
                                                                                            disabled={isLocked}
                                                                                            onClick={() =>
                                                                                                handleBidDeleteButtonPress(
                                                                                                    bidId,
                                                                                                    lot
                                                                                                )
                                                                                            }
                                                                                            size="small"
                                                                                            className="btn-primary mx-1 my-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                                                                            <FaTrashAlt />
                                                                                        </Button>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </>
                                                                        )}
                                                                    </>}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <h4 className="text-black text-center mt-4">
                                        {isTender
                                            ? "No tenders to display"
                                            : "No bids to display"}
                                    </h4>
                                </div>
                            )}
                        </>
                    )}
                </Card>
            </Grid>

            {!isTender && (
                <>
                    {
                        adminBiddingProfile?.paddleNumber && PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalBiddingUser) &&
                        (
                            <Grid item md={3}>
                                <Card className="p-3" style={{ minHeight: 125 }}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={12}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } }>
                                                <h5 className="text-danger">You</h5>
                                                <h5 className="text-danger font-weight-bold">
                                                    {adminBiddingProfile?.paddleNumber}
                                                </h5>
                                            </div>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <BidButton
                                                onBidSuccessfullySubmitted={() => { }}
                                                key="bidding"
                                                auctionId={auctionId}
                                                lotId={lot?.id}
                                                bidAmount={lotAskingBid}
                                                profileId={PrembidLocalStorage.currentProfile} />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <CustomBidButton
                                                onBidSuccessfullySubmitted={() => { }}
                                                key="customBidding"
                                                auctionId={auctionId}
                                                lotId={lot?.id}
                                                profileId={PrembidLocalStorage.currentProfile} />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <MaximumBidButton
                                                onBidSuccessfullySubmitted={() => { }}
                                                key="maximumBidding"
                                                auctionId={auctionId}
                                                lotId={lot?.id}
                                                automaticBidAmount={lotAskingBid}
                                                maximumBidAmount={0}
                                                profileId={PrembidLocalStorage.currentProfile} />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        )

                    }
                    <Grid item md={3}>
                        <Card className="p-3" style={{ minHeight: 125 }}>
                            <Grid container spacing={1}>
                                <Grid item lg={12}>
                                    <LoadingButton
                                        style={{ height: 56 }}
                                        disabled={extendLotIsLoading}
                                        color="primary"
                                        className="btn-primary mb-1 p-3 text-white text-capitalize"
                                        onClick={() => openLotExtensionConfirmation(lotId)}
                                        disableFocusRipple={true}
                                        fullWidth
                                        loading={extendLotIsLoading}
                                    >
                                        <span className="btn-wrapper--icon">
                                            <Typography>Extend (Add 2 Minutes)</Typography>
                                        </span>
                                    </LoadingButton>
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        className="mb-3"
                                        label="Change Increment"
                                        margin="normal"
                                        value={newIncrement}
                                        onChange={onIncrementChange}
                                        id="changeIncrement"
                                        name="value"
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        fullWidth
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    disableTypography={false}>
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <LoadingButton
                                        style={{ height: 56 }}
                                        disabled={updateIncrementIsLoading}
                                        color="primary"
                                        className="btn-primary p-3 text-white text-capitalize"
                                        onClick={() =>
                                            handleUpdateIncrement(lotId, newIncrement, lotCurrentBid)
                                        }
                                        disableFocusRipple={true}
                                        fullWidth
                                        loading={updateIncrementIsLoading}
                                    >
                                        <span className="btn-wrapper--icon">
                                            <FaSave />
                                        </span>
                                    </LoadingButton>
                                </Grid>

                                <Grid item lg={6}>
                                    <TextField
                                        className="mb-3"
                                        label="Change Late Bid Ext"
                                        margin="normal"
                                        value={
                                            newExtension.hour +
                                            "H " +
                                            newExtension.min +
                                            "M " +
                                            newExtension.sec +
                                            "S"
                                        }
                                        disabled
                                        fullWidth
                                        inputProps={{
                                            style: {
                                                textAlign: "center",
                                                minWidth:
                                                    (
                                                        newExtension.hour +
                                                        "H " +
                                                        newExtension.min +
                                                        "M " +
                                                        newExtension.sec +
                                                        "S"
                                                    ).length + "ch",
                                            },
                                        }}
                                    />
                                    <LoadingButton
                                        style={{ height: 56 }}
                                        color="primary"
                                        disabled={showLoadingLateBidExtensionMainButton}
                                        className="btn-primary p-3 text-white text-capitalize"
                                        onClick={handleClickExtensionPopover}
                                        disableFocusRipple={true}
                                        fullWidth
                                        loading={showLoadingLateBidExtensionMainButton}
                                    >
                                        <span className="btn-wrapper--icon">
                                            <FaPlus />
                                        </span>
                                    </LoadingButton>
                                    <Popover
                                        disableScrollLock={false}
                                        open={!!extensionAnchorEl}
                                        anchorEl={extensionAnchorEl}
                                        onClose={handleCloseExtensionPopover}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}>
                                        <List
                                            component="div"
                                            className="list-group-flush text-left bg-transparent">
                                            <ListItem className="d-block rounded-top">
                                                <div className="p-1">
                                                    <TextField
                                                        className="mb-3"
                                                        margin="normal"
                                                        type="number"
                                                        label="Hours"
                                                        style={{}}
                                                        value={newExtension.hour}
                                                        onChange={onChangeExtensionNumeric}
                                                        id="hour"
                                                        name="value"
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        style={{ height: 56 }}
                                                        color="primary"
                                                        className="btn-primary mx-2 my-4 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                        onClick={updateExtensionHour}
                                                        disableFocusRipple={true}>
                                                        <FaPlus />
                                                    </Button>
                                                </div>
                                            </ListItem>

                                            <ListItem className="d-block  rounded-top">
                                                <div className="p-1">
                                                    <TextField
                                                        className="mb-3"
                                                        margin="normal"
                                                        type="number"
                                                        label="Minutes"
                                                        value={newExtension.min}
                                                        onChange={onChangeExtensionNumeric}
                                                        id="min"
                                                        name="value"
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        style={{ height: 56 }}
                                                        color="primary"
                                                        className="btn-primary mx-2 my-4 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                        onClick={updateExtensionMin}
                                                        disableFocusRipple={true}>
                                                        <FaPlus />
                                                    </Button>
                                                </div>
                                            </ListItem>

                                            <ListItem className="d-block  rounded-top">
                                                <div className="p-1">
                                                    <TextField
                                                        className="mb-3"
                                                        margin="normal"
                                                        type="number"
                                                        label="Seconds"
                                                        value={newExtension.sec}
                                                        onChange={onChangeExtensionNumeric}
                                                        id="sec"
                                                        name="value"
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    <Button
                                                        style={{ height: 56 }}
                                                        color="primary"
                                                        className="btn-primary mx-2 my-4 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                        onClick={updateExtensionSec}
                                                        disableFocusRipple={true}>
                                                        <FaPlus />
                                                    </Button>
                                                </div>
                                            </ListItem>

                                            <ListItem className="d-block rounded-bottom p-3 text-center">
                                                <LoadingButton
                                                    style={{ height: 56 }}
                                                    disabled={updateLateBidExtensionIsLoading}
                                                    color="primary"
                                                    fullWidth
                                                    className="btn-primary p-3 align-items-center mb-2 mt-2 text-capitalize"
                                                    onClick={() => {
                                                        handleCloseExtensionPopover();
                                                        handleUpdateLateBidExtension(newExtension, lot);
                                                    }}
                                                    disableFocusRipple={true}
                                                    loading={updateLateBidExtensionIsLoading}
                                                >
                                                    <span className="btn-wrapper--icon">
                                                        <FaSave />
                                                    </span>
                                                </LoadingButton>
                                            </ListItem>
                                        </List>
                                    </Popover>
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        className="mb-3"
                                        label="Change Opening Bid"
                                        margin="normal"
                                        value={openingBid}
                                        onChange={handleSetOpeningBid}
                                        id="changeOpeningBid"
                                        name="value"
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        disabled={
                                            updateStartPriceIsLoading || (bidRows?.length ?? 0) > 0
                                        }
                                        fullWidth
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    disableTypography={false}>
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <LoadingButton
                                        style={{ height: 56 }}
                                        disabled={
                                            updateStartPriceIsLoading || (bidRows?.length ?? 0) > 0
                                        }
                                        color="primary"
                                        className="btn-primary p-3 text-white text-capitalize"
                                        onClick={() => handleUpdateStartPrice(openingBid, lot)}
                                        disableFocusRipple={true}
                                        fullWidth
                                        loading={updateStartPriceIsLoading}
                                    >
                                        <span className="btn-wrapper--icon">
                                            <FaSave />
                                        </span>
                                    </LoadingButton>
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        className="mb-3"
                                        label="Change Reserve Price"
                                        margin="normal"
                                        value={reservePrice}
                                        onChange={handleSetReservePrice}
                                        id="changeReservePrice"
                                        name="value"
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                        fullWidth
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    disableTypography={false}>
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <LoadingButton
                                        style={{ height: 56 }}
                                        disabled={updateReservePriceIsLoading}
                                        color="primary"
                                        className="btn-primary p-3 text-white text-capitalize"
                                        onClick={() => handleUpdateReservePrice(reservePrice, lot)}
                                        disableFocusRipple={true}
                                        fullWidth
                                        loading={updateReservePriceIsLoading}
                                    >
                                        <span className="btn-wrapper--icon">
                                            <FaSave />
                                        </span>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </>
            )}
            {isTender && (
                <Grid item md={3}>
                    <Card className="p-3" style={{ minHeight: 125 }}>
                        <Grid container spacing={1}>
                            <Grid item lg={12}>
                                <LoadingButton
                                    style={{ height: 56 }}
                                    disabled={extendLotIsLoading}
                                    color="primary"
                                    className="btn-primary mb-1 p-3 text-white text-capitalize"
                                    onClick={() => openLotExtensionConfirmation(lotId)}
                                    disableFocusRipple={true}
                                    fullWidth
                                    loading={extendLotIsLoading}
                                >
                                    <span className="btn-wrapper--icon">
                                        <Typography>Extend (Add 2 Minutes)</Typography>
                                    </span>
                                </LoadingButton>
                            </Grid>
                            <Grid item lg={12}>
                                <TextField
                                    className="mb-3"
                                    label="Change Reserve Price"
                                    margin="normal"
                                    value={reservePrice}
                                    onChange={handleSetReservePrice}
                                    id="changeReservePrice"
                                    name="value"
                                    onFocus={(event) => {
                                        event.target.select();
                                    }}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                disableTypography={false}>
                                                <Currency priceWithSymbol={""} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <LoadingButton
                                    style={{ height: 56 }}
                                    disabled={updateReservePriceIsLoading}
                                    color="primary"
                                    className="btn-primary p-3 text-white text-capitalize"
                                    onClick={() => handleUpdateReservePrice(reservePrice, lot)}
                                    disableFocusRipple={true}
                                    fullWidth
                                    loading={updateReservePriceIsLoading}
                                >
                                    <span className="btn-wrapper--icon">
                                        <FaSave />
                                    </span>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            )}
        </Grid>
        <ConfirmationDialog
            open={extendLotModalState.isOpen}
            okButtonText="Confirm"
            cancelButtonText="Cancel"
            title="Add Extension"
            description={<>Are you sure you want to add an extension?</>}
            onOk={confirmExtendLot}
            onCancel={closeLotExtensionConfirmation}
        />
        <ConfirmationDialog
            open={bidDeleteModal.isOpen}
            okButtonText="Confirm"
            cancelButtonText="Cancel"
            title={`Confirm ${isTender ? "Tender" : "Bid"}  Delete`}
            description={deleteBidConfirmationDescription}
            onOk={confirmBidDelete}
            onCancel={cancelBidDelete}
        />
        <BidUpdateDialog
            {...bidEditModal}
            onCancel={cancelBidEdit}
            onOk={confirmBidEdit}
            auctionType={auctionType}
        />
    </Popover>);
};

export default memo(BidsPopover);
